import React from 'react'
import { Datagrid, DateField, Edit, List, SelectInput, SimpleForm, TextField, SimpleFormIterator, TextInput } from "ra-ui-materialui";

const filters = [
    <TextInput source = "employee_id" label="Employee ID" alwaysOn/>,
    <SelectInput label="Status" source="status" choices={[
      { id: 'pending', name: 'Pending' },
      { id: 'approved', name: 'Approved' },
      { id: 'denied', name: 'Denied' },
    ]} alwaysOn/>
  
];
export const DeviceRequestList = ({...props}) => (
    <List {...props} filters={filters} bulkActionButtons={false} filterDefaultValues={{ status: 'pending'}}> 
      <Datagrid expand={<DeviceRequestEdit />} >
      <TextField source="employee_id"/>
      <TextField source="employee_name"/>
      <TextField source="reason"/>
      <TextField source="status"/>
      <DateField source="created_at" />
      </Datagrid>
    </List>
  );

  export const DeviceRequestEdit = (props) => (
    <Edit {...props} >
     <SimpleForm>
         <SelectInput label="Status" source="status" choices={[
      { id: 'pending', name: 'Pending' },
      { id: 'approved', name: 'Approved' },
      { id: 'denied', name: 'Denied' },
    ]} />
     </SimpleForm>
        </Edit>
  )