import * as React from "react";
import { UserShow, UserCreate, UserEdit, UserList } from "./users";
import { PunchCreate, PunchEdit, PunchList, PunchShow } from "./punches";
import { Admin, Resource } from "react-admin";
import {
  FirebaseDataProvider,
  FirebaseAuthProvider
} from "ik-raf-firebase";
import CommentIcon from '@material-ui/icons/Comment';
import AlarmIcon from '@material-ui/icons/Alarm';
import LockResetIcon from '@material-ui/icons/LockRounded';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import CustomLoginPage from './CustomLoginPage';
import { firebaseConfig as config } from './FIREBASE_CONFIG';
import Privacy from "./static/privacy";
import { Route } from "react-router-dom";
import { ReportCreate, ReportList, ReportShow } from "./reports";
import { DeviceRequestEdit, DeviceRequestList } from "./device_requests";

// All options are optional
const options = {
  // Use a different root document to set your resource collections, by default it uses the root collections of firestore
  rootRef: '',
  // Your own, previously initialized firebase app instance
  // app: firebaseAppInstance,
  // Enable logging of react-admin-firebase
  logging: false,
  // Resources to watch for realtime updates, will implicitly watch all resources by default, if not set.
  watch: [],
  // Resources you explicitly dont want realtime updates for
  dontwatch: [],
  // Authentication persistence, defaults to 'session', options are 'session' | 'local' | 'none'
  persistence: 'session',
  // Disable the metadata; 'createdate', 'lastupdate', 'createdby', 'updatedby'
  disableMeta: false,
  // Have custom metadata field names instead of: 'createdate', 'lastupdate', 'createdby', 'updatedby'
  renameMetaFields: {
    created_at: 'my_created_at', // default: 'createdate'
    created_by: 'my_created_by', // default: 'createdby'
    updated_at: 'my_updated_at', // default: 'lastupdate'
    updated_by: 'my_updated_by', // default: 'updatedby'
  },
  // Prevents document from getting the ID field added as a property
  dontAddIdFieldToDoc: true,
  // Adds 'deleted' meta field for non-destructive deleting functionality
  // NOTE: Hides 'deleted' records from list views unless overridden by filtering for {deleted: true} 
  softDelete: false,
  // Changes meta fields like 'createdby' and 'updatedby' to store user IDs instead of email addresses
  associateUsersById: false,
  // Casing for meta fields like 'createdby' and 'updatedby', defaults to 'lower', options are 'lower' | 'camel' | 'snake' | 'pascal' | 'kebab'
  metaFieldCasing: 'lower',
  // Instead of saving full download url for file, save just relative path and then get download url
  // when getting docs - main use case is handling multiple firebase projects (environments)
  // and moving/copying documents/storage files between them - with relativeFilePaths, download url
  // always point to project own storage
  relativeFilePaths: false, 
  // Add file name to storage path, when set to true the file name is included in the path
  useFileNamesInStorage: false,
  // Use firebase sdk queries for pagination, filtering and sorting
  lazyLoading: {
    enabled: true
  },
  // Logging of all reads performed by app (additional feature, for lazy-loading testing)
  firestoreCostsLogger: {
    enabled: false,
    localStoragePrefix: 'cats-storage' // optional
  }
};
const dataProvider = FirebaseDataProvider(config, options);
const authProvider = FirebaseAuthProvider(config, options);

const myAuthProvider = {
  // Copy all authprovider functionality
  ...authProvider,
  // Wrap the login and check for custom claims
  login: async (params) => {
    const user = await authProvider.login(params);
    // getPermissions is how when get the custom claims for the logged in user
    const claims = await authProvider.getPermissions();
    const role = claims.role;
    const isAdmin = role === "admin";
    const isSuperAdmin = role === "superadmin";
    if (isAdmin || isSuperAdmin) {
      return user;
    }
    await authProvider.logout();
    throw new Error("Login error, you arenot authorized for this.");
  },

//   getPermissions: () => {
//     const roles = localStorage.getItem('permissions');
//     console.log(roles);    //This print correctly the array
//     return roles ? Promise.resolve(JSON.parse(roles)) : Promise.reject();
// }
};

const customRoutes = [
  <Route exact path="/privacy" component={Privacy} noLayout/>,
];

class App extends React.Component {
  render() {
    return (
      <Admin
        loginPage={CustomLoginPage} 
        dataProvider={dataProvider}
        authProvider={myAuthProvider}
        customRoutes = {customRoutes}
      >
        <Resource
          name="users"
          icon={AccountBoxIcon}
          list={UserList}
          show={UserShow}
          create={UserCreate}
          edit={UserEdit}
        />
        <Resource
          name="attendance"
          icon={AlarmIcon}
          list={PunchList}
          show={PunchShow}
          edit={PunchEdit}
        />
        <Resource
          name="device_request"
          label="Device Reset Requests"
          icon={LockResetIcon}
          list={DeviceRequestList}
          edit={DeviceRequestEdit}
        />
        <Resource
          name="report"
          icon={CommentIcon}
          list={ReportList}
          show={ReportShow}
          create={ReportCreate}
        />
      </Admin>
    );
  }
}

export default App;
