import * as React from "react";

import { List, Datagrid, Show, Create, SimpleForm, DateField, TextField, SimpleShowLayout, TextInput, SelectInput, BooleanField, BooleanInput, DeleteButton, DateTimeInput, TopToolbar } from 'react-admin';

export const ReportList = (props) => (
    <List {...props} bulkActionButtons={false}>
        <Datagrid rowClick="show">
            <TextField source="title" />
            <DateField source="start_date" />
            <DateField source="end_date" />
            <BooleanField source="only_admins" defaultValue='false' />
            <BooleanField source="success" />
            <TextField source="error_message" />
        </Datagrid>
    </List>
);

export const ReportCreate = (props) => (
    <Create title="Create a report" {...props}>
        <SimpleForm>
            <TextInput source="title" />
            <DateTimeInput source="start_date" />
            <DateTimeInput source="end_date"/>
            <BooleanInput source="only_admins" />
            <SelectInput label="Select Type" source="type" choices={[
    { id: 'weekly', name: 'Weekly' },
    { id: 'monthly', name: 'Monthly' },
  ]} />

        </SimpleForm>
    </Create>
);

function populateList(numbers) {
    if (numbers == null) return <div></div>;
    return numbers.map((number) =>
        <li key={number.toString()}>
            {number.split("-")[0]} - <a href={number.split('-').slice(1).join('-')}>View Report</a>
        </li>
    );
}

const SimpleArray = ({source, record = {}}) =>
    <ul>
        {
            populateList(record[source])
        }
    </ul>;


SimpleArray.defaultProps = {
    addLabel: true,
    label: 'List'
 };


 const PostShowActions = ({ permissions, basePath, data, resource }) => (
    <TopToolbar>
        {permissions.role === 'superadmin' &&
                  <DeleteButton basePath={basePath} record={data} />
        }
    </TopToolbar>
  );
  

export const ReportShow = ({permissions, ...props}) => (
    <Show actions={<PostShowActions permissions={permissions} />} {...props}>
    <SimpleShowLayout>
            <TextField source="id" />
            <TextField source="title" />
            <TextField source="type" />
            <DateField source="start_date" />
            <DateField source="end_date" />
            <BooleanField source="only_admins"/>
            <BooleanField source="success" />
            <SimpleArray source="links" label="Links" />
            <TextField source="error_message" />
        </SimpleShowLayout>
    </Show>
);